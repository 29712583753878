<template>
  <div class="Calculator">
    <div class="Calculator_bac"></div>
    <div class="Calculator_title">
      <h3>薪税计算器</h3>
      <p>智能分配，为您节税</p>
    </div>
    <div class="Calculator_box">
      <div class="Calculator_content">
        <div class="Calculator_top">
          <p class="title_p">公积金缴纳</p>
          <div class="Calculator_top_money">
            <span class="unit_lf">公积金缴纳基数</span>
            <van-field
              v-model="number"
              type="number"
              input-align="right"
              placeholder="请输入"
              @blur="onblur"
            />
            <span class="unit_ri">元/月</span>
          </div>
        </div>
        <div class="Proportion_box">
          <div class="proportion_radio">
            <van-button
              :plain="active !== 1"
              type="primary"
              size="small"
              @click="lowestClick(1)"
              >最低标准</van-button
            >
            <van-button
              :plain="active !== 2"
              type="info"
              size="small"
              @click="lowestClick(2)"
              >全额标准</van-button
            >
            <van-button
              :plain="active !== 3"
              type="danger"
              size="small"
              @click="lowestClick(3)"
              >不缴纳</van-button
            >
          </div>
          <van-popup v-model="showPicker" round position="bottom">
            <van-picker
              show-toolbar
              :columns="columns"
              @cancel="showPicker = false"
              @confirm="onConfirm"
            />
          </van-popup>
        </div>
        <div class="modify_scale">
          <van-button type="info" @click="showPicker = true"
            >修改缴纳比例</van-button
          >
        </div>
        <div class="payment_ratio">
          <div class="payment_ratio_list">
            <h3>缴纳比例</h3>
            <div class="payment_ratio_box van-hairline--bottom">
              <div class="payment_ratio_box_lf">个人缴纳</div>
              <div class="payment_ratio_box_rt">{{ personal }}%</div>
            </div>
            <div class="payment_ratio_box van-hairline--bottom">
              <div class="payment_ratio_box_lf">单位缴纳</div>
              <div class="payment_ratio_box_rt">{{ unit }}%</div>
            </div>
          </div>
          <div class="payment_ratio_list">
            <h3>比例金额</h3>
            <div class="payment_ratio_box van-hairline--bottom">
              <div class="payment_ratio_box_lf">个人缴纳</div>
              <div class="payment_ratio_box_rt">
                {{ (changeNumber * personal) / 100 }}元
              </div>
            </div>
            <div class="payment_ratio_box van-hairline--bottom">
              <div class="payment_ratio_box_lf">单位缴纳</div>
              <div class="payment_ratio_box_rt">
                {{ (changeNumber * unit) / 100 }}元
              </div>
            </div>
            <div class="payment_ratio_box van-hairline--bottom">
              <div class="payment_ratio_box_lf">合计</div>
              <div class="payment_ratio_box_rt">
                {{ (changeNumber * personal + changeNumber * unit) / 100 }}元
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom_but">
        <van-button type="info" @click="$router.go(-1)">确认</van-button>
      </div>
    </div>
    <copyrightIp class="padding-t" />
  </div>
</template>
<script>
import { Field, Button, Toast, Popup, Picker } from "vant";
import { findAllTAccumulationFund } from "@/api/Calculator";
import copyrightIp from "@/components/copyrightIp";
export default {
  name: "ProvidentFund",
  components: {
    [Field.name]: Field,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Toast.name]: Toast,
    copyrightIp,
  },
  beforeDestroy() {},
  data() {
    return {
      number: "",
      changeNumber: "",
      infoNum: null, // 税前工资
      active: "",
      personal: "12", // 个人
      unit: "12", // 单位
      lowBase: "", //最低标准
      upperBase: "", //最高标准
      showPicker: false,
      columns: [
        // 第一列
        {
          values: [
            "个人5%",
            "个人6%",
            "个人7%",
            "个人8%",
            "个人9%",
            "个人10%",
            "个人11%",
            "个人12%",
          ],
          defaultIndex: 1,
        },
        // 第二列
        {
          values: [
            "公司5%",
            "公司6%",
            "公司7%",
            "公司8%",
            "公司9%",
            "公司10%",
            "公司11%",
            "公司12%",
          ],
          defaultIndex: 2,
        },
      ],
    };
  },
  created() {
    this.infoNum = this.$route.query.number || 0;
    this.getFindAllTAccumulationFund();
  },
  mounted() {},
  beforeDestroy() {
    let num = (this.changeNumber * this.personal) / 100;
    let unitNum = (this.changeNumber * this.unit) / 100;
    sessionStorage.setItem("providentFund", num);
    sessionStorage.setItem("providentFundUnit", unitNum);
  },
  methods: {
    // 输入框失焦事件
    onblur() {
      this.isInterval();
      this.changeNumber = this.number;
      this.active = "";
    },
    // 切换标准
    lowestClick(type) {
      this.active = type;
      this.personal = 12; // 个人
      this.unit = 12; // 单位
      switch (type) {
        case 1:
          this.changeNumber = this.lowBase;
          this.number = this.lowBase;
          break;
        case 2:
          if (this.infoNum > this.upperBase) {
            this.changeNumber = this.upperBase;
            this.number = this.upperBase;
            Toast(`本市最高缴纳基数${this.number}`);
          } else if (this.infoNum < this.lowBase) {
            this.changeNumber = this.lowBase;
            this.number = this.lowBase;
            this.active = 1;
            Toast(`本市最低缴纳基数${this.changeNumber}`);
          } else {
            this.changeNumber = this.infoNum;
            this.number = this.infoNum;
          }
          break;
        case 3:
          this.changeNumber = 0;
          this.$router.push("/calculator/moon");
          break;
      }
    },
    onConfirm(value) {
      this.personal = value[0].split("个人")[1].split("%")[0]; // 个人
      this.unit = value[1].split("公司")[1].split("%")[0]; // 单位
      this.showPicker = false;
    },
    // 判断是否超出区间
    isInterval(num = this.number) {
      num = Number(num);
      let min = Number(this.lowBase);
      let max = Number(this.upperBase);
      if (num < min) {
        this.number = min;
        this.active = 1;
        Toast(`本市最低缴纳基数${this.number}`);
      } else if (this.infoNum && num > this.infoNum && num < max) {
        this.number = this.infoNum;
        this.active = 2;
        Toast("缴纳基数不能大于税前收入哦!");
      } else if (num > max) {
        this.number = max;
        this.active = 2;
        Toast(`本市最高缴纳基数${this.number}`);
      } else {
        this.number = num;
      }
      this.changeNumber = this.number;
    },
    // 获取公积金
    getFindAllTAccumulationFund() {
      findAllTAccumulationFund().then((res) => {
        let data = res.data.data[0];
        this.lowBase = Number(data.lowBase);
        this.upperBase = Number(data.upperBase);
        this.isInterval(this.infoNum);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.padding-t {
  padding-top: 27px;
}
.Calculator {
  width: 100%;
  position: relative;

  .Calculator_bac {
    width: 100%;
    height: 250px;
    background-image: url("../../../assets/img/Utilities1_Moon_01.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .Calculator_title {
    padding: 10px 10px 0;
    color: #fff;
    h3 {
      font-size: 20px;
      font-weight: bold;
    }
    p {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  .Calculator_box {
    width: 100%;
    padding: 10px;

    .Calculator_content {
      width: 100%;
      padding: 10px;
      background: #fff;
      border-radius: 10px;
      margin-top: 5px;
    }
    .bottom_but {
      background: #fff;
      padding: 10px;
      .van-button {
        width: 100%;
        border-radius: 6px;
      }
    }
  }
}
.Calculator_top {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  padding: 10px;
  font-weight: bold;
  .title_p {
    font-size: 17px;
    text-align: center;
  }
  .Calculator_top_money {
    display: flex;
    align-items: center;
    padding: 10px 0;
    .unit_lf {
      // font-size: 35px;
      font-size: 16px;
      min-width: 115px;
      color: #081222;
      font-weight: 100;
    }
    .unit_ri {
      min-width: 38px;
      color: #999999;
    }
    ::v-deep .van-cell {
      padding-left: 3px;
      .van-cell__value {
        font-size: 25px;
      }
      input::-webkit-input-placeholder {
        color: #ccc;
        font-size: 25px;
        font-weight: 100;
        line-height: 80px;
      }
    }
  }
}
.Proportion_box {
  padding: 10px 0;
  .proportion_radio {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    .van-button {
      min-width: 66px;
      border-radius: 5px;
    }
  }
}
.modify_scale {
  padding: 15px 0 10px;
  .van-button {
    width: 100%;
  }
}
.payment_ratio {
  width: 100%;
  padding: 10px;
  .payment_ratio_list {
    padding-bottom: 20px;
    h3 {
      position: relative;
      font-size: 16px;
      padding-left: 10px;
      &::before {
        content: "";
        position: absolute;
        top: 4px;
        left: 0;
        width: 3px;
        height: 15px;
        background: #3684f8;
      }
    }
    .payment_ratio_box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px 0;

      font-size: 14px;
      .payment_ratio_box_rt {
        color: #3784f8;
      }
    }
  }
}
</style>
